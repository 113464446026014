import React from 'react';

const PaymentSuccessPage = () => {
  return (
    <div id='payment-success-container' className='payment-container'>
      <div id='payment-success-content' className='payment-content'>
        <label>Payment successful</label>
      </div>
    </div>
  )
}

export default PaymentSuccessPage;
