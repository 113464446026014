const AboutPage = () => {
  return (
    <div className='panel-without-sidebar'>
      <div id="about-wrapper">
        <p>We move everything! Malayicha zonke!</p>
        <div id='shout-out'>
          <span class="unsplash-shoutout">
            Photo by <a href="https://unsplash.com/@mitchel3uo?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Mitchell Luo</a> on <a href="https://unsplash.com/s/photos/trucks?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a>
          </span>

          <span class="unsplash-shoutout">
            Photo by <a href="https://unsplash.com/@nardly?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Daniel Bernard</a> on <a href="https://unsplash.com/s/photos/trucks?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a>
          </span>

          <span class="unsplash-shoutout">
            Photo by <a href="https://unsplash.com/@daveballew?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">David Ballew</a> on <a href="https://unsplash.com/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
