import React from 'react';

const PaymentFailPage = () => {
  return (
    <div id='payment-container' className='payment-container'>
      <div id='payment-success-content' className='payment-content'>
        <label>Payment failed</label>
      </div>
    </div>
  )
}

export default PaymentFailPage;
